import { getIntl } from 'localization'
import randomColor from 'utils/randomColor'
import titleCase from 'utils/titleCase'
import { v4 } from 'uuid'

export const initialChart = {
  offset: {
    x: 0,
    y: 0,
  },
  nodes: {},
  links: {},
  picked: {
    nodes: [],
    links: [],
    ports: [],
  },
  selected: {},
  hovered: {},
}

const task_node = ({ position }) => ({
  data: {
    type: 'node',
    ports: {
      enterance: {
        id: 'enterance',
        type: 'top',
        condition: 'node',

        properties: {
          custom: 'property',
        },
      },
      exit: {
        id: 'exit',
        type: 'bottom',
        condition: 'node',

        properties: {
          custom: 'property',
        },
      },
    },
    properties: {
      subtype: 'task',
      name: getIntl('New Task'),
      detail: {},
      chart: initialChart,
      parameters: [],
    },
  },
  position,
})

const if_node = ({ position }) => ({
  data: {
    type: 'if',
    ports: {
      enterance: {
        id: 'enterance',
        type: 'top',
        condition: 'if',
        properties: {
          custom: 'property',
        },
      },
      then: {
        id: 'then',
        type: 'left',
        condition: 'if',
        properties: {
          custom: 'if',
        },
      },
      else: {
        id: 'else',
        type: 'right',
        condition: 'if',
        properties: {
          custom: 'else',
        },
      },
    },
    properties: {
      name: getIntl('If Node'),

      detail: {},
    },
  },
  position,
})

const while_node = ({ position, subtype, definers, color }) => ({
  data: {
    type: 'while',
    ports: {
      enterance: {
        id: 'enterance',
        type: 'top',
        condition: 'while',
      },
      exit: {
        id: 'exit',
        type: 'bottom',
        condition: 'while',
      },
    },
    properties: {
      name: getIntl(`While ${titleCase(subtype)}`),
      color,
      subtype,
      definers,
      detail: {},
    },
  },
  position: {
    x: subtype === 'start' ? position.x : position.x + 50,
    y: subtype === 'start' ? position.y : position.y + 200,
  },
})

const forEach_node = ({ position, subtype, definers, color }) => ({
  data: {
    type: 'forEach',
    ports: {
      enterance: {
        id: 'enterance',
        type: 'top',
        condition: 'while',
      },
      exit: {
        id: 'exit',
        type: 'bottom',
        condition: 'while',
      },
    },
    properties: {
      name: getIntl(`For Each ${titleCase(subtype)}`),
      color,
      subtype,
      definers,
      detail: {},
    },
  },
  position: {
    x: subtype === 'start' ? position.x : position.x + 50,
    y: subtype === 'start' ? position.y : position.y + 200,
  },
})

const tryCatch_node = ({ subtype, position, definers, color }) => {
  const getPosition = type => {
    switch (type) {
      case 'try':
        return {
          x: position.x,
          y: position.y,
        }
      case 'catch':
        return {
          x: position.x,
          y: position.y + 200,
        }
      case 'final':
        return {
          x: position.x,
          y: position.y + 500,
        }
      default:
        return {
          x: position.x,
          y: position.y,
        }
    }
  }
  return {
    data: {
      type: 'tryCatch',
      ports: {
        enterance: {
          id: 'enterance',
          type: 'top',
          condition: 'tryCatch',
        },
        exit: {
          id: 'exit',
          type: 'bottom',
          condition: 'tryCatch',
        },
      },
      properties: {
        name: getIntl(`${titleCase(subtype)}`),
        color,
        subtype,
        definers,
        detail: {},
      },
    },
    position: getPosition(subtype),
  }
}

export const newNode = ({ type, position }, callback) => {
  const color = randomColor()
  const start_id = v4()
  const end_id = v4()
  const catch_id = v4()

  switch (type) {
    case 'task':
      callback(task_node({ position }))
      break
    case 'if_task':
      callback(if_node({ position }))
      break
    case 'if_step':
      callback(if_node({ position }))
      break
    case 'while_task':
      callback(while_node({ subtype: 'start', position, definers: { start_id, end_id }, color }))
      callback(while_node({ subtype: 'end', position, definers: { start_id, end_id }, color }))
      break
    case 'while_step':
      callback(while_node({ subtype: 'start', position, definers: { start_id, end_id }, color }))
      callback(while_node({ subtype: 'end', position, definers: { start_id, end_id }, color }))
      break
    case 'forEach_task':
      callback(forEach_node({ subtype: 'start', position, definers: { start_id, end_id }, color }))
      callback(forEach_node({ subtype: 'end', position, definers: { start_id, end_id }, color }))
      break
    case 'forEach_step':
      callback(forEach_node({ subtype: 'start', position, definers: { start_id, end_id }, color }))
      callback(forEach_node({ subtype: 'end', position, definers: { start_id, end_id }, color }))
      break
    case 'tryCatch_task':
      callback(
        tryCatch_node({
          subtype: 'try',
          position,
          definers: { start_id, catch_id, final_id: end_id },
          color,
        }),
      )
      callback(
        tryCatch_node({
          subtype: 'catch',
          position,
          definers: { start_id, catch_id, final_id: end_id },
          color,
        }),
      )
      callback(
        tryCatch_node({
          subtype: 'final',
          position,
          definers: { start_id, catch_id, final_id: end_id },
          color,
        }),
      )
      break
    case 'tryCatch_step':
      callback(
        tryCatch_node({
          subtype: 'try',
          position,
          definers: { start_id, catch_id, final_id: end_id },
          color,
        }),
      )
      callback(
        tryCatch_node({
          subtype: 'catch',
          position,
          definers: { start_id, catch_id, final_id: end_id },
          color,
        }),
      )
      callback(
        tryCatch_node({
          subtype: 'final',
          position,
          definers: { start_id, catch_id, final_id: end_id },
          color,
        }),
      )
      break
    default:
      break
  }
}

export const getDebugEnabledStepsForTask = (active_scenario, nodeId) => {
  const steps = []
  if (active_scenario.content.chart.nodes[nodeId].type === 'node') {
    Object.keys(active_scenario.content.chart.nodes[nodeId].properties.chart.nodes).forEach(key => {
      const node = active_scenario.content.chart.nodes[nodeId].properties.chart.nodes[key]
      if (node.properties.detail.devOptions && node.properties.detail.devOptions.debug === true) {
        steps.push(node)
      }
    })
  }
  return steps
}

export const getDebugEnabledNodesForScenario = active_scenario => {
  const debugNodes = {}
  Object.keys(active_scenario.content.chart.nodes).forEach(taskId => {
    if (
      active_scenario.content.chart.nodes[taskId].type === 'node' &&
      active_scenario.content.chart.nodes[taskId].properties.chart.nodes
    ) {
      Object.keys(active_scenario.content.chart.nodes[taskId].properties.chart.nodes).forEach(
        key => {
          const node = active_scenario.content.chart.nodes[taskId].properties.chart.nodes[key]
          const taskName =
            active_scenario.content.chart.nodes[taskId].properties.customName ||
            active_scenario.content.chart.nodes[taskId].properties.name
          if (
            node.properties.detail.devOptions &&
            node.properties.detail.devOptions.debug === true
          ) {
            const data = {
              id: node.id,
              name: node.properties.customName || node.properties.name,
              taskName,
            }
            Object.assign(debugNodes, {
              [taskId]: debugNodes[taskId] ? [...debugNodes[taskId], data] : [data],
            })
          }
        },
      )
    }
  })
  return debugNodes
}
